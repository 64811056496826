
import { defineComponent, ref, onMounted, watch, PropType } from 'vue';
import IMask from 'imask/esm/imask';
import AInput from 'ant-design-vue/es/input';
import { EventHandler } from 'ant-design-vue/es/_util/EventInterface';
import type { SizeType } from 'ant-design-vue/es/config-provider/index';

export default defineComponent({
  components: {
    AInput,
  },
  props: {
    /**
     * Значение
     */
    modelValue: {
      type: [String, Number],
      default: '',
    },
    /**
     * Тип ввода
     */
    type: {
      type: String as PropType<
        | 'number'
        | 'search'
        | 'time'
        | 'date'
        | 'week'
        | 'month'
        | 'submit'
        | 'hidden'
        | 'button'
        | 'image'
        | 'text'
        | 'reset'
        | 'checkbox'
        | 'radio'
        | 'color'
        | 'range'
        | 'tel'
        | 'url'
        | 'email'
        | 'datetime-local'
        | 'file'
        | 'password'
      >,
      default: 'text',
    },
    /**
     * Максимальная длина ввода
     */
    maxlength: {
      type: Number,
      default: 255,
    },
    /**
     * Отключение ввода
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Маска (imask)
     * https://imask.js.org/guide.html
     */
    mask: {
      type: Object as PropType<IMask.AnyMaskedOptions>,
      default: () => null,
    },
    /**
     * Вывод ошибки
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * Текст ошибки
     */
    errorMessage: {
      type: String,
      default: '',
    },
    /**
     * Заполнитель
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Добавить кнопку сброса ввода
     */
    allowClear: {
      type: Boolean,
      default: false,
    },
    /**
     * Шаг
     */
    step: {
      type: String,
      default: '',
    },
    /**
     * Размер поля ввода
     */
    size: {
      type: String as PropType<SizeType>,
      default: 'default',
    },
    /**
     * Размер поля ввода
     */
    status: {
      type: String as PropType<'error' | 'warning'>,
      required: false,
    },
  },
  setup(props, { emit }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let maskInstance: IMask.Masked<any> | null = null;
    const inputLocalValue = ref<string | number>(props.modelValue);

    function initMask() {
      maskInstance = IMask.createMask(props.mask);
      // изначально не проверяем пустые поля
      if (props.modelValue) {
        updateMaskValue(props.modelValue?.toString() || '');
      }
    }

    function updateMaskValue(value: string) {
      if (!maskInstance) {
        return;
      }
      maskInstance.resolve(value);
      inputLocalValue.value = maskInstance.value;
      emit('update:modelValue', inputLocalValue.value);
    }

    onMounted(() => {
      if (props.mask) {
        initMask();
      }
    });

    watch(
      () => props.mask,
      (newMask) => {
        if (newMask) {
          initMask();
        }
      },
    );

    watch(
      () => props.modelValue,
      (newValue) => {
        if (props.mask) {
          // не перепроверяем маску, если значение не изменилось
          if (props.modelValue !== inputLocalValue.value) {
            updateMaskValue(props.modelValue?.toString() || '');
          }
          return;
        }
        inputLocalValue.value = newValue;
      },
    );

    const handleInput: EventHandler = (event) => {
      let value = event.target?.value || '';
      if (props.mask && maskInstance) {
        updateMaskValue(value);
        return;
      }
      inputLocalValue.value = value;
      emit('update:modelValue', value);
    };

    const inputRef = ref();
    const focus = () => inputRef.value.$el.querySelector('input').focus();

    return {
      inputLocalValue,
      handleInput,
      pressEnter: () => emit('pressEnter'),

      inputRef,
      focus,
    };
  },
});
