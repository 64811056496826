
import { defineComponent, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { PlusOutlined } from '@ant-design/icons-vue';
import { ROUTE_PARAMS_TYPE } from '@/router';
import useCabinetStore from '@/store/CabinetStore';

export default defineComponent({
  components: {
    IconPlusOutlined: PlusOutlined,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { company, userRole } = useCabinetStore();
    const addPaymentButton = ref();

    function gotoPayment() {
      if (route.name === 'balance') {
        window.dispatchEvent(new CustomEvent('payment', { detail: { ref: addPaymentButton } }));
      } else {
        router.push({
          name: 'balance',
          params: { companyId: company.value.id, add: 'true' },
        });
      }
    }

    return {
      company,
      userRole,
      isDepartment: computed(() => route.params.type === ROUTE_PARAMS_TYPE.department),
      tkBalance: computed(() => company.value?.payments?.balance ?? 0),

      gotoPayment,
    };
  },
});
