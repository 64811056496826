
import { PropType, defineComponent } from 'vue';
import ASelect, { DefaultOptionType, SelectValue } from 'ant-design-vue/es/select';

export default defineComponent({
  components: {
    ASelect,
  },
  props: {
    value: {
      type: Object as PropType<SelectValue>,
      required: false,
    },
    options: {
      type: Array as PropType<DefaultOptionType[]>,
      required: false,
    },
    maxTagCount: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<'multiple' | 'tags'>,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    status: {
      type: String as PropType<'error' | 'warning'>,
      required: false,
    },
  },
});
