import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ASelect = _resolveComponent("ASelect")!

  return (_openBlock(), _createBlock(_component_ASelect, _mergeProps({
    value: _ctx.value,
    options: _ctx.options,
    maxTagCount: _ctx.maxTagCount,
    mode: _ctx.mode,
    placeholder: _ctx.placeholder,
    disabled: _ctx.disabled,
    loading: _ctx.loading,
    status: _ctx.status
  }, _ctx.$attrs, {
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event)))
  }), {
    maxTagPlaceholder: _withCtx((omittedValues) => [
      _renderSlot(_ctx.$slots, "maxTagPlaceholder", _normalizeProps(_guardReactiveProps(omittedValues)), () => [
        _createTextVNode("+ " + _toDisplayString(omittedValues.length), 1)
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["value", "options", "maxTagCount", "mode", "placeholder", "disabled", "loading", "status"]))
}