import dayjs, { Dayjs } from 'dayjs';
import daysjs from 'dayjs';

export const utc3offset = 3 * 60;

function _normalizeTimeInDateRange(dates: Date[], precise = false) {
  const localOffset = dates[0].getTimezoneOffset();

  let startTime = dayjs(dates[0]);
  let endTime = dayjs(dates[1]);

  if (precise === false) {
    startTime = startTime.startOf('day');
    endTime = endTime.endOf('day');
  }

  return [startTime.subtract(localOffset + utc3offset, 'minute'), endTime.subtract(localOffset + utc3offset, 'minute')];
}

// конверация в +3 таймзону
export function normalizeTimeInDateRange(dates: Date[], precise = false) {
  if (!dates.length) {
    return [];
  }

  const dateRange = _normalizeTimeInDateRange(dates, precise);
  return [dateRange[0].toISOString(), dateRange[1].toISOString()];
}

// конверация в +3 таймзону
export function normalizeTimeInDateRange2(dates: Date[], precise = false) {
  if (!dates.length) {
    return [];
  }

  const dateRange = _normalizeTimeInDateRange(dates, precise);
  return [dateRange[0].toDate(), dateRange[1].toDate()];
}

export function getTodayDateRange() {
  const dateNow = daysjs();
  return [dateNow.startOf('day').toDate().toISOString(), dateNow.endOf('day').toDate().toISOString()];
}

export function getTodayDateRange2(): [Date, Date] {
  const dateNow = daysjs();
  return [dateNow.startOf('day').toDate(), dateNow.endOf('day').toDate()];
}

export function getDefaultTimeRanges(): [[Date, Date], [Date, Date], [Date, Date]] {
  const dateToday = daysjs();
  const dateYesterday = dateToday.add(-1, 'day');

  const dateToDateRange = (startDate: Dayjs, endDate: Dayjs): [Date, Date] => {
    return [startDate.startOf('day').toDate(), endDate.endOf('day').toDate()];
  };

  const dateRangeToday = dateToDateRange(dateToday, dateToday);
  const dateRangeYesterday = dateToDateRange(dateYesterday, dateYesterday);
  const dateRangeMonth: [Date, Date] = [dateToday.startOf('month').toDate(), dateToday.endOf('day').toDate()];

  return [dateRangeToday, dateRangeYesterday, dateRangeMonth];
}
