import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ad076c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "mr-2 d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VDecimal = _resolveComponent("VDecimal")!
  const _component_IconPlusOutlined = _resolveComponent("IconPlusOutlined")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VDecimal, { value: _ctx.tkBalance }, null, 8, ["value"]),
      _createTextVNode("₽")
    ]),
    (!_ctx.isDepartment && _ctx.userRole === 'admin' && _ctx.company.type === 'tk')
      ? (_openBlock(), _createBlock(_component_UiButton, {
          key: 0,
          ref: "addPaymentButton",
          class: "d-flex",
          type: "primary",
          size: "small",
          shape: "circle",
          onClick: _ctx.gotoPayment
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconPlusOutlined, { class: "icon-plus" })
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}