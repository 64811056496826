import { api } from '@/store';
import { q } from '@/store/api';
import { getAuthToken } from '@/store/auth';
import {
  GetOfflineStationPageQueryVariables,
  StationsFetchStationsListQueryVariables,
  StationsGetStationsPageQueryVariables,
  StationsUpdateStationInfoAndTransactionsQueryVariables,
  AddCompanyStationMutationVariables,
  DownloadStationsIntegrationsQueryVariables,
  BulkEditCompanyStationsMutationVariables,
  AddColorMutationVariables,
  EditCompanyStationMutationVariables,
  StationsFetchStationIntegrationTabQueryVariables,
  StationsSaveStationIntegrationMutationVariables,
  StationsUpdatePartnerStationsMutationVariables,
  StationsIndexStationsMutationVariables,
  GetAzsCompanyStationsQueryVariables,
  StationsGetStationPageQueryVariables,
  StationsRefreshStationPageQueryVariables,
  StationsGetStationOrdersQueryVariables,
  StationsAddAsuMutationVariables,
  StationsGetMapStationQueryVariables,
  StationsGetStationCoordsQueryVariables,
  StationsGetCompanyStationsQueryVariables,
  StationsSetStationStateMutationVariables,
  StationsBulkSetStationStateMutationVariables,
  StationsGetStationStateLogsQueryVariables,
  CopyStationsMutationVariables,
  GetUpdatedStationsQueryVariables,
  BulkSetStationsStateByGroupIdMutationVariables,
  StationsStateLogsGroupsQueryVariables,
  AdminGetStationPageQueryVariables,
  GetStationDataQueryVariables,
  StationsGetStationPricesDiscountsQueryVariables,
  StationsGetStationPricesQueryVariables,
  SetStationDiscountPriceMutationVariables,
} from '@/gql/graphql';
import useQuery from '@/helpers/useQuery';
import { graphql } from '@/gql';

export function getFuelStations({
  companyId,
  contractId,
}: {
  companyId?: string;
  contractId?: string;
}): Promise<GetFuelStationsResult> {
  return api
    .graphql({
      query: q`
      query getStations($companyId: ID, $contractId: ID, $partnerId: ID) {
        cabinet {
            company(id: $companyId, contractId: $contractId, partnerId: $partnerId) {
                id 
                name 
                brand
                stations {
                    items { 
                        id 
                        name
                        address
                        lastOnline
                        status
                        point
                    }
                    count
                    status { status count }
                }   
            }
        }
      }
    `,
      variables: { companyId, contractId },
      token: getAuthToken(),
    })
    .then(({ cabinet }) => {
      return {
        items: cabinet?.company.stations.items || [],
        statistic: cabinet?.company.stations.status || [],
        count: cabinet?.company.stations.count || 0,
      };
    });
}

type GetFuelStationsResult = {
  items: FuelStation[];
  statistic: FuelStationsStatistic;
  count: number;
};

export type FuelStation = {
  id: string;
  name: string;
  address?: string | null;
  status?: FuelStationStatus;
  lastOnline?: string;
  point?: number[];
  prices?: FuelStationPrice[];
  distance?: number;
  integration?: FuelStationIntegration;
};
export type FuelStationsStatistic = { status: FuelStationStatus; count: number }[];
export type FuelStationStatus = 'online' | 'offline' | 'unknown';
export type FuelStationIntegration = {
  login: string;
  password: string;
};
export type FuelStationPrice = {
  fuel?: {
    id: string;
    name: string;
  };
  pumps?: number[];
  price?: number;
};

export function createStationAsu(data: NewStationSystemData) {
  return api.graphql({
    query: q`
        mutation createStationAsu($data: NewStationSystemData!) {
          addStationSystem(data: $data) {
            id
          }
        }
      `,
    variables: {
      data,
    },
    token: getAuthToken(),
  });
}

export type NewStationSystemData = {
  name: string;
  protocol: string;
};

export function fetchStationAsus() {
  return api
    .graphql({
      query: q`
        query fetchStationAsus() {
          systems {
            id
            name
            protocol
            isPing
          }
        }
      `,
      token: getAuthToken(),
    })
    .then((result) => result.systems);
}

export function getStationIntegrationTab(companyId: string) {
  return api
    .graphql({
      query: q`
        query fetchStationIntegrationTab($id: ID) {
          cabinet {
            company(id: $id) {
              ... on AzsCompany {
                stationPartner {
                  id
                  name
                  outgoing
                  apiType
                  system {
                    id
                    name
                    protocol
                    isPing
                  }
                }
                stationPartnerFuels {
                  partnerFuelId
                  fuelId
                  name
                  ename
                }
              }
            }
          }
          systems {
            id
            name
            protocol
            isPing
          }
          fuel {
            id
            name
          }
        }
      `,
      variables: {
        id: companyId,
      },
      token: getAuthToken(),
    })
    .then((result) => result);
}

export function changeStationSystemParams(companyId: string, data: StationSystemParams) {
  return api.graphql({
    query: q`
        mutation changeStationSystemParams($companyId: ID!, $data: CompanyData!) {
          saveCompany(companyId: $companyId, data: $data) {
            id
          }
        }
      `,
    variables: {
      companyId,
      data,
    },
    token: getAuthToken(),
  });
}

export type StationSystemParams = {
  stationPartner?: {
    systemId?: string;
    apiKey?: string;
    apiUrl?: string;
  };

  stationPartnerFuels?: StationPartnerFuel[];
};

export type StationPartnerFuel = {
  partnerFuelId: string;
  fuelId: number;
  name: string | null;
  ename: string | null;
};

export function changeSystemParams(data: StationSystemData) {
  return api.graphql({
    query: q`
        mutation changeSystemParams($data: StationSystemData!) {
          editStationSystem(data: $data) {
            id
          }
        }
      `,
    variables: {
      data,
    },
    token: getAuthToken(),
  });
}

export type StationSystemData = {
  id: string;
  isPing: boolean;
};

export async function stationsGetStationPage(v: StationsGetStationPageQueryVariables) {
  const q = graphql(`
    query stationsGetStationPage($companyId: ID, $contractId: ID, $stationId: ID!, $filter: OrdersQueryParams!) {
      cabinet {
        company(id: $companyId, contractId: $contractId) {
          id
          name
          station(id: $stationId) {
            name
            status
            address
            isActive
            integration {
              login
              password
            }
          }
          ordersNew(filter: $filter) {
            count
            items {
              id
              type
              date
              status
              statusMessage
              user {
                phone
              }
              corp {
                driver {
                  name
                  phone
                }
                car {
                  number
                }
              }
              station {
                name
                address
              }
              ... on OnlineOrder {
                item {
                  fuel {
                    name
                  }
                  quantityReport
                  quantityReq
                  costReport {
                    fact
                    station
                    corp
                  }
                  costReq {
                    fact
                    station
                    corp
                  }
                  prices {
                    fact
                    station
                    corp
                  }
                  pump
                }
              }
            }
            summary {
              name
              count
              quantity
              total
              discount
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function adminGetStationPage(v: AdminGetStationPageQueryVariables) {
  const q = graphql(`
    query adminGetStationPage($companyId: ID, $contractId: ID, $stationId: ID!, $filter: OrdersQueryParams!) {
      cabinet {
        company(id: $companyId, contractId: $contractId) {
          id
          name
          station(id: $stationId) {
            name
            status
            address
            isActive
            lastOnline
            integration {
              login
              password
            }
          }
          ... on AzsCompany {
            stationPartner {
              system {
                isPing
              }
            }
          }
          ordersNew(filter: $filter) {
            count
            items {
              id
              type
              date
              status
              statusMessage
              user {
                phone
              }
              corp {
                driver {
                  name
                  phone
                }
                car {
                  number
                }
              }
              station {
                name
                address
              }
              payment {
                type
                source
              }
              ... on OnlineOrder {
                item {
                  fuel {
                    name
                  }
                  quantityReport
                  quantityReq
                  costReport {
                    fact
                    station
                    corp
                  }
                  costReq {
                    fact
                    station
                    corp
                  }
                  prices {
                    fact
                    station
                    corp
                  }
                  pump
                }
              }
            }
            summary {
              name
              count
              quantity
              total
              discount
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function getStationData(v: GetStationDataQueryVariables) {
  const q = graphql(`
    query getStationData($companyId: ID!, $stationId: ID!) {
      cabinet {
        company(id: $companyId) {
          station(id: $stationId) {
            name
            promo
            address
            color
            point
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export function stationsRefreshStationPage(v: StationsRefreshStationPageQueryVariables) {
  const q = graphql(`
    query stationsRefreshStationPage($companyId: ID, $stationId: ID!) {
      cabinet {
        company(id: $companyId) {
          id
          name
          station(id: $stationId) {
            name
            status
            address
            isActive
            integration {
              login
              password
            }
          }
        }
      }
    }
  `);

  return useQuery(q, v).then((r) => r);
}

export function stationsGetStationOrders(v: StationsGetStationOrdersQueryVariables) {
  const q = graphql(`
    query stationsGetStationOrders($companyId: ID, $filter: OrdersQueryParams!) {
      cabinet {
        company(id: $companyId) {
          ordersNew(filter: $filter) {
            count
            items {
              id
              type
              date
              status
              statusMessage
              user {
                phone
              }
              corp {
                driver {
                  name
                  phone
                }
              }
              station {
                name
                address
              }
              payment {
                type
                source
              }
              ... on OnlineOrder {
                item {
                  fuel {
                    name
                  }
                  quantityReport
                  quantityReq
                  costReport {
                    fact
                    station
                    corp
                  }
                  costReq {
                    fact
                    station
                    corp
                  }
                  prices {
                    fact
                    station
                    corp
                  }
                  pump
                }
              }
            }
            summary {
              name
              count
              quantity
              total
              discount
            }
          }
        }
      }
    }
  `);

  return useQuery(q, v).then((r) => r);
}

export async function stationsGetStationCoords(v: StationsGetStationCoordsQueryVariables) {
  const q = graphql(`
    query stationsGetStationCoords($stationId: ID!) {
      station(id: $stationId) {
        name
        point
        address
      }
    }
  `);

  return await useQuery(q, v);
}

export async function stationsGetStationPrices(v: StationsGetStationPricesQueryVariables) {
  const q = graphql(`
    query stationsGetStationPrices($stationId: ID!) {
      station(id: $stationId) {
        prices {
          fuel {
            shortName
          }
          pumps
          price
        }
      }
    }
  `);

  return useQuery(q, v);
}

export async function stationsGetStationPricesDiscounts(v: StationsGetStationPricesDiscountsQueryVariables) {
  const q = graphql(`
    query stationsGetStationPricesDiscounts($companyId: ID!, $stationId: ID!) {
      cabinet {
        company(id: $companyId) {
          station(id: $stationId) {
            prices {
              fuel {
                id
                shortName
              }
              pumps
              price
            }
            stationFuelDiscounts {
              id
              fuel
              discount
            }
          }
        }
      }
    }
  `);

  return useQuery(q, v);
}

export async function setStationDiscountPrice(v: SetStationDiscountPriceMutationVariables) {
  const q = graphql(`
    mutation setStationDiscountPrice($discountId: ID, $stationId: ID!, $fuelId: ID, $discount: Float!) {
      setStationFuelDiscount(discountId: $discountId, stationId: $stationId, fuelId: $fuelId, discount: $discount)
    }
  `);

  return await useQuery(q, v);
}

export async function addCompanyStation(v: AddCompanyStationMutationVariables) {
  const q = graphql(`
    mutation AddCompanyStation($companyId: ID!, $data: NewStationData!) {
      addStation(companyId: $companyId, stationData: $data) {
        id
      }
    }
  `);

  return await useQuery(q, v);
}

export type NewStationData = {
  name: string;
  partnerId: string;
  address: string;
  lat: number | null;
  lng: number | null;
};

export async function editCompanyStation(v: EditCompanyStationMutationVariables) {
  const q = graphql(`
    mutation EditCompanyStation($stationId: ID!, $data: EditStationData!) {
      editStation(stationId: $stationId, stationData: $data) {
        id
      }
    }
  `);

  return await useQuery(q, v);
}

export type EditStationData = {
  name: string;
  address: string;
  promo: string;
  color: string;
  lat: number | string | undefined;
  lng: number | string | undefined;
};

export type EditStationStatus = {
  active: boolean;
};

export async function bulkEditCompanyStations(v: BulkEditCompanyStationsMutationVariables) {
  const q = graphql(`
    mutation BulkEditCompanyStations($companyId: ID!, $data: BulkEditStationsData!) {
      bulkEditStations(companyId: $companyId, stationsData: $data) {
        id
      }
    }
  `);

  return await useQuery(q, v);
}

export async function getStationCompanyId(stationId: string) {
  const q = graphql(`
    query getStationCompanyId($stationId: ID!) {
      station(id: $stationId) {
        organizationId
      }
    }
  `);

  return (await useQuery(q, { stationId })).station?.organizationId;
}

export type BulkEditStationsData = {
  promo: string;
  color: string;
};

export type BulkEditStationsStatus = {
  active: boolean;
};

export function addColor(v: AddColorMutationVariables) {
  const q = graphql(`
    mutation AddColor($name: String!, $color: String!) {
      addColor(name: $name, color: $color) {
        id
      }
    }
  `);

  return useQuery(q, v);
}

export function getStationTransactions({
  companyId,
  stationId,
  filterDateFrom,
  filterDateTo,
  filterSearch,
  filterStatus,
  page,
  pageSize,
  showErrors,
}: {
  companyId: string;
  stationId: string;
  filterDateFrom?: string;
  filterDateTo?: string;
  filterSearch?: string;
  filterStatus?: string;
  page?: number;
  pageSize?: number;
  showErrors?: boolean;
}) {
  return api
    .graphql({
      query: q`
        query getStationTransactions($companyId: ID, $ordersFilter: OrdersQueryParams!) {
          cabinet {
            company(id: $companyId) {
              ordersNew (filter: $ordersFilter) {
                count
                items {
                  id
                  date
                  status
                  statusMessage
                  user { phone }
                  corp {
                    driver {
                      name
                      phone
                    }
                  }
                  station { 
                    name address 
                  }
                  payment {
                    type
                    source
                  }
                  ... on OnlineOrder {
                    item {
                      fuel { name }
                      quantityReport
                      quantityReq
                      costReport { fact station }
                      costReq { fact station }
                      prices { fact station }
                      pump
                    }
                  }
                }
                summary {
                  name
                  count
                  quantity
                  total
                  discount
                }
              }
            }
          }
        }
      `,
      variables: {
        companyId,
        ordersFilter: {
          showErrors,
          stationId,
          dateFrom: filterDateFrom,
          dateTo: filterDateTo,
          query: filterSearch,
          status: filterStatus,
          page,
          size: pageSize,
        },
      },
      token: getAuthToken(),
    })
    .then(({ cabinet }) => {
      return cabinet?.company || {};
    });
}

export function getTransactionsReportUrl({
  companyId,
  stationId,
  dateFrom,
  dateTo,
  filterSearch,
  filterStatus,
}: {
  companyId?: string;
  stationId?: string;
  dateFrom?: string;
  dateTo?: string;
  filterSearch?: string;
  filterStatus?: string;
}) {
  return api
    .graphql({
      query: q`
        query getOrdersReportUrl (
          $companyId: ID,
          $reportFileFormat: ReportFormat,
          $filter: OrdersQueryParams!
        ) {
          cabinet {
            company(id: $companyId) {
              ordersNewReportLink(
                format: $reportFileFormat
                filter: $filter
              )
            }
          }
        }
      `,
      variables: {
        companyId,
        filter: {
          stationId,
          status: filterStatus,
          query: filterSearch,
          dateFrom,
          dateTo,
        },
        reportFileFormat: 'excel',
      },
      token: getAuthToken(),
    })
    .then((result) => result?.cabinet?.company.ordersNewReportLink || '');
}

export async function downloadStationsIntegrations(v: DownloadStationsIntegrationsQueryVariables) {
  const q = graphql(`
    query DownloadStationsIntegrations($companyId: ID) {
      cabinet {
        company(id: $companyId) {
          integrationsReportLink
        }
      }
    }
  `);

  return (await useQuery(q, v)).cabinet?.company?.integrationsReportLink ?? '';
}

export async function GetOfflineStationPage(variables: GetOfflineStationPageQueryVariables) {
  const GetOfflineStationPageQuery = graphql(`
    query GetOfflineStationPage($contractId: ID!, $stationId: ID!, $filter: OrdersQueryParams!) {
      cabinet {
        company(contractId: $contractId) {
          station(id: $stationId) {
            name
            address
            integration {
              login
              password
            }
          }
          ordersNew(filter: $filter) {
            count
            items {
              id
              date
              station {
                address
              }
              status
              statusMessage
              ... on OfflineOrder {
                owner {
                  name
                  shortName
                }
                cardNumber
                items {
                  id
                  type
                  quantity
                  groupName
                  shortName
                  prices {
                    corp
                    fact
                    user
                    station
                  }
                  cost {
                    corp
                    fact
                    user
                    station
                  }
                }
              }
            }
            summary {
              name
              count
              quantity
              total
            }
          }
        }
      }
    }
  `);

  return await useQuery(GetOfflineStationPageQuery, variables);
}

export async function fetchStationsList(variables: StationsFetchStationsListQueryVariables) {
  const fetchStationsListQuery = graphql(`
    query StationsFetchStationsList($size: Int!, $offset: Int!, $query: String) {
      stationsByES(size: $size, offset: $offset, query: $query) {
        count
        items {
          id
          name
          address
        }
      }
    }
  `);

  return (await useQuery(fetchStationsListQuery, variables)).stationsByES;
}

export async function stationsGetStationsPage(v: StationsGetStationsPageQueryVariables) {
  const q = graphql(`
    query StationsGetStationsPage($companyId: ID!, $stationId: ID!, $filter: OrdersQueryParams!) {
      station(id: $stationId) {
        name
        address
      }
      cabinet {
        company(id: $companyId) {
          ordersNew(filter: $filter) {
            count
            summary {
              name
              count
              total
              discount
              quantity
            }
            items {
              id
              date
              status
              statusMessage
              ... on OnlineOrder {
                item {
                  fuel {
                    name
                  }
                  quantityReport
                  quantityReq
                  costReport {
                    fact
                    station
                    corp
                  }
                  costReq {
                    fact
                    station
                    corp
                  }
                  prices {
                    fact
                    station
                    corp
                  }
                  pump
                }
              }
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v).then((r) => r);
}

export async function updateStationInfoAndTransactions(v: StationsUpdateStationInfoAndTransactionsQueryVariables) {
  const q = graphql(`
    query StationsUpdateStationInfoAndTransactions($companyId: ID!, $filter: OrdersQueryParams!) {
      cabinet {
        company(id: $companyId) {
          ordersNew(filter: $filter) {
            count
            summary {
              name
              count
              total
              discount
              quantity
            }
            items {
              id
              date
              status
              statusMessage
              ... on OnlineOrder {
                item {
                  fuel {
                    name
                  }
                  quantityReport
                  quantityReq
                  costReport {
                    fact
                    station
                    corp
                  }
                  costReq {
                    fact
                    station
                    corp
                  }
                  prices {
                    fact
                    station
                    corp
                  }
                  pump
                }
              }
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function stationsAddAsu(v: StationsAddAsuMutationVariables) {
  const q = graphql(`
    mutation stationsAddAsu($data: NewStationSystemData!) {
      addStationSystem(data: $data) {
        id
      }
    }
  `);

  return await useQuery(q, v);
}

export async function fetchStationIntegrationTab(v: StationsFetchStationIntegrationTabQueryVariables) {
  const q = graphql(`
    query StationsFetchStationIntegrationTab($id: ID) {
      cabinet {
        company(id: $id) {
          ... on AzsCompany {
            stationPartner {
              id
              name
              outgoing
              apiType
              apiKey
              system {
                id
                name
                protocol
                isPing
              }
            }
            stationPartnerFuels {
              partnerFuelId
              fuelId
              name
              ename
            }
          }
        }
        serverAsu
      }
      systems {
        id
        name
        protocol
        isPing
      }
      fuel {
        id
        name
      }
    }
  `);

  return await useQuery(q, v);
}

export async function saveStationIntegration(v: StationsSaveStationIntegrationMutationVariables) {
  const q = graphql(`
    mutation StationsSaveStationIntegration(
      $companyId: ID!
      $companyData: CompanyData!
      $stationData: StationSystemData!
    ) {
      editStationSystem(data: $stationData) {
        id
      }
      saveCompany(companyId: $companyId, data: $companyData) {
        id
      }
    }
  `);

  return await useQuery(q, v);
}

export async function updatePartnerStations(v: StationsUpdatePartnerStationsMutationVariables) {
  const q = graphql(`
    mutation StationsUpdatePartnerStations($companyId: ID!) {
      updatePartnerStations(companyId: $companyId)
    }
  `);

  return await useQuery(q, v);
}

export async function indexStations(v?: StationsIndexStationsMutationVariables) {
  const q = graphql(`
    mutation StationsIndexStations($stationId: ID) {
      indexStations(id: $stationId)
    }
  `);

  return await useQuery(q, v);
}

export async function getCompanyStations(v?: GetAzsCompanyStationsQueryVariables) {
  const q = graphql(`
    query GetAzsCompanyStations(
      $companyId: ID!
      $contractsType: String!
      $page: Int
      $size: Int
      $query: String
      $status: String
    ) {
      cabinet {
        company(id: $companyId) {
          stations(contractsType: $contractsType, page: $page, size: $size, query: $query, status: $status) {
            count
            items {
              id
              name
              address
              lastOnline
              status
            }
            status {
              status
              count
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function stationsGetCompanyStations(v?: StationsGetCompanyStationsQueryVariables) {
  const q = graphql(`
    query stationsGetCompanyStations($companyId: ID!, $query: String) {
      cabinet {
        company(id: $companyId) {
          stations(query: $query) {
            items {
              id
              name
              address
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function stationsGetStations() {
  const q = graphql(`
    query stationsGetStations {
      stationsByES(size: 10000) {
        items {
          id
          name
          address
          point
          prices {
            fuel {
              code
            }
            price
          }
        }
      }
    }
  `);

  return await useQuery(q);
}

export async function stationsGetMapStations() {
  const q = graphql(`
    query stationsGetMapStations {
      stationsByES(size: 10000) {
        items {
          id
          point
        }
      }
    }
  `);

  return await useQuery(q);
}

export async function stationsGetMapStation(v: StationsGetMapStationQueryVariables) {
  const q = graphql(`
    query stationsGetMapStation($id: ID!) {
      station(id: $id) {
        name
        address
        prices {
          fuel {
            code
          }
          price
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function stationsSetStationState(v: StationsSetStationStateMutationVariables) {
  const q = graphql(`
    mutation stationsSetStationState($id: ID!, $state: StationState!, $commentary: String!) {
      setStationState(id: $id, state: $state, commentary: $commentary)
    }
  `);

  return await useQuery(q, v);
}

export async function stationsBulkSetStationState(v: StationsBulkSetStationStateMutationVariables) {
  const q = graphql(`
    mutation stationsBulkSetStationState(
      $companyId: ID!
      $oldState: [StationState!]!
      $newState: StationState!
      $commentary: String!
      $query: String
    ) {
      bulkSetStationsState(
        companyId: $companyId
        oldState: $oldState
        newState: $newState
        query: $query
        commentary: $commentary
      )
    }
  `);

  return await useQuery(q, v);
}

export async function bulkSetStationsStateByGroupId(v: BulkSetStationsStateByGroupIdMutationVariables) {
  const q = graphql(`
    mutation bulkSetStationsStateByGroupId($groupId: ID!, $state: StationState!, $commentary: String!) {
      bulkSetStationsStateByGroupId(groupId: $groupId, state: $state, commentary: $commentary)
    }
  `);

  return await useQuery(q, v);
}

export async function stationsStateLogsGroups(v: StationsStateLogsGroupsQueryVariables) {
  const q = graphql(`
    query stationsStateLogsGroups($companyId: ID!, $page: Int!, $size: Int!) {
      cabinet {
        company(id: $companyId) {
          stationsStateLogsGroups(filter: { page: $page, size: $size }) {
            items {
              id
              date
              state
              email
              commentary
            }
            count
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function stationsGetStationStateLogs(v: StationsGetStationStateLogsQueryVariables) {
  const q = graphql(`
    query stationsGetStationStateLogs($companyId: ID!, $stationId: ID!, $page: Int!, $size: Int!) {
      cabinet {
        company(id: $companyId) {
          station(id: $stationId) {
            stateLogs(filter: { page: $page, size: $size }) {
              items {
                date
                user
                state
                commentary
              }
              count
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}

export async function copyStations(v: CopyStationsMutationVariables) {
  const q = graphql(`
    mutation copyStations($companyId: ID!, $ids: [ID!]!) {
      copyStations(ids: $ids, companyId: $companyId)
    }
  `);

  return await useQuery(q, v);
}

export async function getUpdatedStations(v: GetUpdatedStationsQueryVariables) {
  const q = graphql(`
    query getUpdatedStations($id: ID!, $filter: QueryParams!) {
      cabinet {
        company(id: $id) {
          updatedStations(filter: $filter) {
            count
            items {
              id
              name
              nameApi
              address
              addressApi
              organizationName
              lng
              lngApi
              lat
              latApi
            }
          }
        }
      }
    }
  `);

  return await useQuery(q, v);
}
