/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosResponse, Method } from 'axios';
import jwtDecode from 'jwt-decode';
import ls from '@/plugins/ls';
import { ACCESS_TOKEN_KEY } from '@/store/auth';
import apiConfig from '@/config/api';

/**
 * Будет проверять токен перед запросом.
 * Если он истекает, будет обновление.
 * Сложность в том, что какой-то процесс может послать запрос в момент, когда какой-то другой уже это сделал.
 * Будет конфликт и ошибка. Тут попытаюсь ее предупредить.
 */
export const checkToken = (() => {
  let lock = 0;
  const queue: any[] = [];
  return async (token?: string | null): Promise<string | null> => {
    if (!token) {
      return null;
    }
    const exp = (jwtDecode(token) as any)?.exp || 0;
    if (exp) {
      if (exp < Math.floor(+new Date() / 1000) + 20) {
        const localLock = ++lock;
        if (localLock > 1) {
          lock--;
          // кто-то уже заблокировал
          return new Promise((resolve, reject) => {
            queue.push({ resolve, reject });
          });
        }
        return post({
          url: '/refresh',
        })
          .then(({ token }) => {
            ls.set(ACCESS_TOKEN_KEY, token, true);
            setTimeout(() => {
              queue.forEach(({ resolve }) => resolve(token));
              queue.splice(0);
            }, 0);
            return token; // request(method, { ...params, token }, false);
          })
          .catch((error) => {
            console.log(error);
            if (['session expired', 'user not found'].includes(error.response.data.message)) {
              ls.remove(ACCESS_TOKEN_KEY, true);
            }
            setTimeout(() => {
              queue.forEach(({ reject }) => reject(token));
              queue.splice(0);
            }, 0);
            throw error;
          })
          .finally(() => {
            lock = 0;
          });
      }
    }
    return token ?? null;
  };
})();

/**
 * Параметры запроса к API
 */
type ApiRequest = {
  url: string;
  params?: { [param: string]: string | null | undefined };
  data?: unknown;
  token?: string | null;
  timeout?: number;
};

/**
 * Базовый запрос
 */
const request = async (method: Method, params: ApiRequest, repeat = true): Promise<any> => {
  const token = await checkToken(params.token);
  return axios({
    baseURL: apiConfig.base,
    method,
    ...params,
    headers: {
      ...(token ? { 'x-auth-token': `Bearer ${token}` } : {}),
    },
  })
    .then((res: AxiosResponse) => {
      if (res.status > 201) {
        throw res;
      }
      return res.data;
    })
    .catch((error) => {
      const message = error.response.data?.message;
      if (repeat && message && message.includes('jwt expired')) {
        // попробовать обновить токен и повторить запрос
        return post({ url: '/refresh' })
          .then(({ token }) => {
            ls.set(ACCESS_TOKEN_KEY, token, true);
            return request(method, { ...params, token }, false);
          })
          .catch((error) => {
            if (['session expired', 'user not found'].includes(error.response.data.message)) {
              ls.remove(ACCESS_TOKEN_KEY, true);
            }
            throw error;
          });
      }
      if (message && message.includes('session expired')) {
        ls.remove(ACCESS_TOKEN_KEY, true);
      }
      throw error;
    });
};

/**
 * Формат функций запросов GET, POST, PUT, DEL
 */
type ApiCall = {
  (req: ApiRequest): Promise<any>;
};

const get: ApiCall = (params) => request('get', params);

const post: ApiCall = (params) => request('post', params);

const put: ApiCall = (params) => request('put', params);

const del: ApiCall = (params) => request('delete', params);

/**
 * Параметры GraphQL-запроса
 */
interface GraphQLCall {
  query: string;
  variables?: any;
  token?: string | null;
  timeout?: number;
}

export class GraphQLError extends Error {
  requestId: string;

  constructor(message: string, requestId: string) {
    super(message);
    this.name = 'GraphQLError';
    this.requestId = requestId;
  }
}

/**
 * GraphQL-запрос
 */
const graphql = ({ query, variables, token, timeout }: GraphQLCall) =>
  post({
    url: '/graphql',
    data: {
      query,
      variables,
    },
    token,
    timeout,
  }).then(({ data, errors }: any | { data: any; errors: Array<any> }) => {
    const error = (errors ? errors[0] : null) || null;
    if (error) {
      throw new GraphQLError(error, error.extensions.requestId);
    }
    return data;
  });

/**
 * Подчистка GraphQL запроса
 */
export const q = (strings: TemplateStringsArray, ...args: string[]): string =>
  strings
    .map((s, i) => s + (args[i] || ''))
    .join(' ')
    .replace(/[\n\t ]+/g, ' ')
    .replace(/([{}():,])\s+/g, '$1')
    .replace(/\s+([{}():,])/g, '$1')
    .replace(/\s\s+/g, ' ')
    .trim();

export default {
  get,
  post,
  put,
  delete: del,
  graphql,
};
