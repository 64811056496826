
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SberId',
  methods: {
    connect() {
      const query = this.$route.query['source'] || this.$route.query['utm_source'];
      const utm = query ? String(query) : 'sberbusinessID';
      document.location.href = '/api/sbidConnect' + (utm ? `?utm_source=${utm}` : '');
    },
  },
});
